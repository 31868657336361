
import ErrorComponent from '../../../components/error/ErrorComponent';
import { STRINGS } from '../../../utilities/strings';

const ConfirmationFailure = () => {
    return (
        <ErrorComponent 
            title={STRINGS.GENERIC_DDE_ERROR} 
            alert={STRINGS.ACCOUNT_DETAILS_SUBMISSION_FAILED_ALERT} 
            message={`${STRINGS.ACCOUNT_DETAILS_SUBMISSION_FAILED_MESSAGE} \n ${STRINGS.CONTACT_REP} \n `} 
        />
    );
  };
  
  export default ConfirmationFailure;