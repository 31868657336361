import { Disclaimer, Footer as LMDSFooter } from '@lmig/lmds-react';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import { apiGwService } from '../../services/api-gw-service';
import { useState, useEffect } from 'react';
import { useAppState } from '../../context/AppContext';
import ConfirmationSuccess from './components/ConfirmationSuccess';
import ConfirmationFailure from './components/ConfirmationFailure';

const Confirmation = () => {
  const [iframeSuccess, setIframeSuccess] = useState(false);
  const [iframeSubmissionError, setIframeSubmissionError] = useState(false);
  const { state } = useAppState();
  const { user } = state;
  const [enrollmentComplete, setEnrollmentComplete] = useState(false);


  useEffect(() => {
    if (!iframeSuccess) {
      console.log('--- Call SUBMIT API')
      apiGwService.iframeSubmission.submit("submitted", user?.id).then(response => {
        if(!response.isSubmissionSuccessful || response.errorOccured){
          setIframeSubmissionError(true);
        }
        else{
          setEnrollmentComplete(true)
        }
        setIframeSuccess(true)
      });
    }
  }, [iframeSuccess, user?.id])

  if (!iframeSuccess) {
    return <LoadingSpinner />
  }

  return (
    <span className="confirmation">
      {enrollmentComplete &&  <ConfirmationSuccess />}
      {iframeSubmissionError && <ConfirmationFailure/>}
      <LMDSFooter>
        <hr className="horizontal-rule" />
        <Disclaimer>
          <p>© 2023 Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116</p>
        </Disclaimer>
      </LMDSFooter>
    </span >

  );
};

export default Confirmation;