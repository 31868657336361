export const STRINGS = {
  AUTH_ERROR: 'Authentication Error',
  AUTH_REQUIRED: 'Authentication is required.',
  VALIDATION_ERROR: 'Validation Error',
  INCORRECT_DATA: 'Incorrect data entered. Please ensure you enter the correct details.',
  VALIDATION_FAILED: 'Account validation has failed.',
  CONTACT_REP: 'Please contact your claims representative for assistance.',
  TRY_AGAIN: 'We encountered an error. Please try again later.',
  ACCOUNT_DETAILS_SUBMISSION_FAILED_ALERT: "Error processing account details",
  ACCOUNT_DETAILS_SUBMISSION_FAILED_MESSAGE: "We're sorry, an error occurred while processing your account details and we were unable to complete your Direct Deposit enrollment.",
  GENERIC_DDE_ERROR: "Direct Deposit Enrollment Error",
  EXPIRATION_ERROR: "Expiry Error",
  EXPIRED_ACCOUNT_ALERT: "This link is expired",
  COMPLETED_ERROR: "Enrollment Complete",
  COMPLETED_ALERT: "This account has already completed enrollment.",
  COMPLETE_INFO: "Payment will be made to the account details provided during enrollment.",
};
