import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';
import React, { useContext, useEffect } from 'react';
import { Route, Routes} from 'react-router-dom';
import AppProvider, { AppStateContext, getUser, useAppState } from "./client/context/AppContext";
import AccountCapture from "./client/pages/accountCapture/AccountCapture";
import Verification from './client/pages/verification/Verification';
import { theme } from "./client/theme/base-theme";
import Authentication from "./client/components/authentication/Authentication";
import Auth from "./client/pages/auth/Auth";
import Confirmation from "./client/pages/confirmation/Confirmation";
import Terms from "./client/pages/terms/Terms";
import { SelectPaymentType } from "./client/pages/selectPaymentType/SelectPaymentType";

export function HomeContainer() {
    const { state } = useAppState();
    const { user } = state;

    const urlParams = new URLSearchParams(window.location.search);

    let loginParam = urlParams.get("login")
    if(!loginParam && user && user.loginParam){
        loginParam = user.loginParam
    }   

    let idParam = urlParams.get("id")
    if(!idParam && user && user.idParam){
        idParam = user.idParam
    }

    const {dispatch } = useContext(AppStateContext);
    useEffect(() => {
        getUser(dispatch, loginParam, idParam);
    }, [dispatch, loginParam, idParam])
    
    return (
        <ThemeProvider theme={theme}>
            <Authentication>    
            <CssBaseline />
                <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                    <Routes>
                        <Route path="/" element={<Auth /> }/>
                        <Route path="/Verification" element={<Verification /> }/>
                        <Route path="/selectPaymentType" element={<SelectPaymentType /> }/>
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/accountCapture" element={<AccountCapture />} />
                        <Route path="/confirmation" element={<Confirmation />} />
                    </Routes>
                </Container>
            </Authentication>   
        </ThemeProvider>

    );
}

export default function App() {
    return (
        <React.Fragment>
            <AppProvider>
                <HomeContainer />
            </AppProvider>
        </React.Fragment>
    );
}

