import { ConfirmationDisplay } from './confirmationDisplay';
import {Heading} from '@lmig/lmds-react';

const ConfirmationSuccess = () => {
    return (
        <>
            <Heading type="h2-bold">Direct Deposit Enrollment Confirmation</Heading> 
            <ConfirmationDisplay />
            <p>Thank you. Your request to enroll has been processed.</p>
        </>
    );
  };
  
  export default ConfirmationSuccess;